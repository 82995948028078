<template>
  <v-app>
    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="pl-0">{{ $route.meta.pageName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <span class="hidden-xs-only">{{ employee.full_name }}</span>

      <v-menu
        offset-y
        bottom
        left
        :close-on-content-click="false"
        v-model="notifyMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            small
            v-bind="attrs"
            v-on="on"
            class="px-1"
            color="white"
          >
            <v-badge
              bordered
              overlap
              color="error"
              :content="notifyCount > 9 ? '9+' : notifyCount"
              v-if="notifyCount > 0"
            >
              <v-icon>mdi-bell-outline</v-icon>
            </v-badge>
            <v-icon v-else>mdi-bell-outline</v-icon>
          </v-btn>
        </template>
        <v-card
          class="mx-auto"
          max-width="400"
          min-width="300"
          max-height="500"
        >
          <NotifyList v-if="notifyMenu" @onNotifyChange="onNotifyChange" />
        </v-card>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            v-bind="attrs"
            v-on="on"
            small
            color="white"
            class="px-1"
          >
            <img
              v-if="$i18n.locale === 'vi'"
              src="@/assets/sidebars/vietnam.png"
              alt=""
              style="height: 32px"
            />
            <img
              v-else-if="$i18n.locale === 'cn'"
              src="@/assets/sidebars/china.png"
              alt=""
              style="height: 32px; padding: 3px"
            />
            <img
              v-else
              src="@/assets/sidebars/usa.png"
              alt=""
              style="height: 32px"
            />
            <v-icon small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            class="cursor-pointer"
            @click="changeLanguage('vi')"
            :class="{ 'primary white--text': $i18n.locale === 'vi' }"
          >
            <v-list-item-title>
              <div class="d-flex align-center">
                <img
                  src="@/assets/sidebars/vietnam.png"
                  alt=""
                  style="height: 22px"
                  class="mr-1"
                />
                <span>Tiếng Việt</span>
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            class="cursor-pointer"
            @click="changeLanguage('en')"
            :class="{ 'primary white--text': $i18n.locale === 'en' }"
          >
            <v-list-item-title>
              <div class="d-flex align-center">
                <img
                  src="@/assets/sidebars/usa.png"
                  alt=""
                  style="height: 22px"
                  class="mr-1"
                />
                <span>English</span>
              </div>
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item
            class="cursor-pointer"
            @click="changeLanguage('cn')"
            :class="{ 'primary white--text': $i18n.locale === 'cn' }"
          >
            <v-list-item-title>
              <div class="d-flex align-center">
                <img
                  src="@/assets/sidebars/china.png"
                  alt=""
                  style="height: 22px"
                  class="mr-1"
                />
                <span>简体中文</span>
              </div>
            </v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            small
            v-bind="attrs"
            v-on="on"
            color="white"
            class="px-1"
          >
            <span class="fs-16 primary--text">
              {{ warehouse.code_name || $t("labels.warehouse") }}
            </span>
            <v-icon small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense style="min-width: 100px">
          <v-list-item
            v-for="item in warehouses"
            :key="`wh_${item.id}`"
            class="cursor-pointer"
            @click="changeWarehouse(item.id)"
            :class="{ 'primary white--text': item.id === warehouse.id }"
          >
            <v-list-item-title> {{ item.code_name }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            v-bind="attrs"
            v-on="on"
            small
            color="white"
            class="px-0"
            style="min-width: 30px"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item v-if="isMobile()">
            <v-list-item-title>{{ employee.full_name }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showDialogChangePassword">
            <v-list-item-title>
              {{ $t("labels.change_password") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="sto" @click="backToAdmin">
            <v-list-item-title>Quay lại tài khoản Admin</v-list-item-title>
          </v-list-item>
          <v-list-item v-else @click="logout">
            <v-list-item-title>{{ $t("labels.logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- :mini-variant.sync="mini" -->
    <v-navigation-drawer v-model="drawer" app>
      <v-toolbar elevation="4" light>
        <a href="/">
          <v-img
            height="35"
            contain
            src="https://ssc.eco/images/ongvang-icon-scroll.png"
          ></v-img>
        </a>
      </v-toolbar>

      <v-divider></v-divider>

      <v-list dense class="pb-80">
        <div v-for="item in items" :key="item.title">
          <template
            v-if="
              (!item.permissions ||
                item.permissions.length === 0 ||
                checkPermission(item.permissions)) &&
              checkMenuMobile(item.mobileCheck) &&
              checkMenuDesktop(item.desktopCheck)
            "
          >
            <!-- item group -->
            <v-list-group v-if="item.children" v-model="item.active">
              <template v-slot:prependIcon>
                <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                <img v-if="item.img" :src="item.img" width="24px" alt="" />
              </template>
              <template v-slot:activator>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </template>

              <!-- item group child -->
              <div v-for="child in item.children" :key="child.title">
                <v-list-item
                  v-if="
                    (!child.permissions ||
                      child.permissions.length === 0 ||
                      checkPermission(child.permissions)) &&
                    checkMenuMobile(item.mobileCheck) &&
                    checkMenuDesktop(item.desktopCheck)
                  "
                  :to="{ name: child.route }"
                >
                  <v-list-item-icon>
                    <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                    <img
                      v-if="child.img"
                      :src="child.img"
                      width="24px"
                      alt=""
                    />
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="text-right purple--text">{{
                      child.title
                    }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon v-if="child.appendIcon">
                      {{ child.appendIcon }}
                    </v-icon>
                    <img
                      v-if="child.appendImg"
                      :src="child.appendImg"
                      width="24px"
                      alt=""
                    />
                  </v-list-item-icon>
                </v-list-item>
              </div>
            </v-list-group>
            <!-- end item group -->

            <!-- item -->
            <v-list-item
              v-else
              link
              :to="{ name: item.route }"
              @click="checkRoute(item.route)"
            >
              <v-list-item-icon>
                <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                <img v-if="item.img" :src="item.img" width="24px" alt="" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon
                v-if="item.elCountName && menuCount[item.elCountName]"
              >
                <v-badge
                  color="error"
                  :content="menuCount[item.elCountName]"
                  inline
                ></v-badge>
              </v-list-item-icon>
            </v-list-item>
            <!-- end item -->
          </template>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <!-- audio list -->
    <audio id="error_sound_player">
      <source type="audio/mpeg" :src="require('@/assets/audio/error.wav')" />
    </audio>
    <audio id="success_sound_player">
      <source type="audio/mpeg" :src="require('@/assets/audio/success.wav')" />
    </audio>

    <div v-for="audio in audios" :key="`ad_${audio}`">
      <audio :id="`${audio}_player`">
        <source
          type="audio/mpeg"
          :src="require(`@/assets/audio/${audioPrefix}${audio}.wav`)"
        />
      </audio>
    </div>

    <!-- <audio id="DonDaHuy_player">
      <source type="audio/mpeg" :src="require('@/assets/audio/DonDaHuy.wav')" />
    </audio>
    <audio id="DonDaQuet_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/DonDaQuet.wav')"
      />
    </audio>
    <audio id="KhongCoQuyenXuLy_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/KhongCoQuyenXuLy.wav')"
      />
    </audio>
    <audio id="MaDonKhongTonTai_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/MaDonKhongTonTai.wav')"
      />
    </audio>
    <audio id="NhamVanChuyen_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/NhamVanChuyen.wav')"
      />
    </audio>
    <audio id="NhamSotRoi_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/NhamSotRoi.wav')"
      />
    </audio>
    <audio id="NhamDonRoi_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/NhamDonRoi.wav')"
      />
    </audio>
    <audio id="TrangThaiKhongHopLe_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/TrangThaiKhongHopLe.wav')"
      />
    </audio>
    <audio id="TrangThaiKhongHopLe_0_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/TrangThaiKhongHopLe_0.wav')"
      />
    </audio>
    <audio id="TrangThaiKhongHopLe_20_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/TrangThaiKhongHopLe_20.wav')"
      />
    </audio>
    <audio id="TrangThaiKhongHopLe_35_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/TrangThaiKhongHopLe_35.wav')"
      />
    </audio>
    <audio id="TrangThaiKhongHopLe_40_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/TrangThaiKhongHopLe_40.wav')"
      />
    </audio>
    <audio id="TrangThaiKhongHopLe_60_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/TrangThaiKhongHopLe_60.wav')"
      />
    </audio>
    <audio id="TrangThaiKhongHopLe_100_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/TrangThaiKhongHopLe_100.wav')"
      />
    </audio>
    <audio id="TrangThaiKhongHopLe_160_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/TrangThaiKhongHopLe_160.wav')"
      />
    </audio>
    <audio id="TrangThaiKhongHopLe_200_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/TrangThaiKhongHopLe_200.wav')"
      />
    </audio>
    <audio id="TrangThaiKhongHopLe_220_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/TrangThaiKhongHopLe_220.wav')"
      />
    </audio>
    <audio id="TrangThaiKhongHopLe_240_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/TrangThaiKhongHopLe_240.wav')"
      />
    </audio>
    <audio id="QuetMaBaoBiHoacMaDonHang_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/QuetMaBaoBiHoacMaDonHang.wav')"
      />
    </audio>
    <audio id="ChoBienBanBanGiao_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/ChoBienBanBanGiao.wav')"
      />
    </audio>
    <audio id="SotBaoThieu_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/SotBaoThieu.wav')"
      />
    </audio>
    <audio id="ChuaHoanThanhSot1SP_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/ChuaHoanThanhSot1SP.wav')"
      />
    </audio>
    <audio id="DonHoaToc_player">
      <source
        type="audio/mpeg"
        :src="require('@/assets/audio/DonHoaToc.wav')"
      />
    </audio> -->

    <v-dialog v-model="dialogChangePassword" persistent max-width="350px">
      <v-card v-if="dialogChangePassword">
        <v-card-title class="text-h6 mb-3">
          {{ $t("labels.change_password") }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="oldPassword"
            class="c-input-small mb-3"
            :label="$t('labels.old_password')"
            :type="passwordShow ? 'text' : 'password'"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="off"
            @click:append="passwordShow = !passwordShow"
            autocapitalize="off"
            autofocus
            dense
            outlined
            hide-details
          ></v-text-field>

          <v-text-field
            v-model="newPassword"
            class="c-input-small mb-3"
            :label="$t('labels.new_password')"
            :type="passwordShow ? 'text' : 'password'"
            :disabled="!oldPassword"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="off"
            @click:append="passwordShow = !passwordShow"
            autocapitalize="off"
            autofocus
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideDialogChangePassword">
            {{ $t("labels.cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="changePassword"
            :disabled="!oldPassword || !newPassword || newPassword.length < 6"
          >
            {{ $t("labels.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { isMobile } from "@/libs/helpers";
import { httpClient } from "@/libs/http";

export default {
  name: "App",

  components: {
    NotifyList: () => import("@/components/common/NotifyList"),
  },

  data: () => ({
    drawer: false,
    items: [],
    mini: true,
    sto: null,
    employee: {},
    notifyCount: 0,
    notifyMenu: false,
    dialogChangePassword: false,
    oldPassword: null,
    newPassword: null,
    passwordShow: false,
    isLoading: false,
    menuCount: {},
    warehouses: [],
    warehouse: {},
    audioPrefix: "",
    audios: [
      "DonDaHuy",
      "DonDaQuet",
      "DonDaQuetInTem2Lan",
      "KhongCoQuyenXuLy",
      "MaDonKhongTonTai",
      "NhamVanChuyen",
      "NhamSotRoi",
      "NhamDonRoi",
      "DonCoQuaTang",
      "TrangThaiKhongHopLe",
      "TrangThaiKhongHopLe_0",
      "TrangThaiKhongHopLe_20",
      "TrangThaiKhongHopLe_35",
      "TrangThaiKhongHopLe_40",
      "TrangThaiKhongHopLe_60",
      "TrangThaiKhongHopLe_100",
      "TrangThaiKhongHopLe_160",
      "TrangThaiKhongHopLe_200",
      "TrangThaiKhongHopLe_220",
      "TrangThaiKhongHopLe_240",
      "QuetMaBaoBiHoacMaDonHang",
      "ChoBienBanBanGiao",
      "SotBaoThieu",
      "ChuaHoanThanhSot1SP",
      "DonHoaToc",
      "DungXePickup",
      "PickupXong",
      "DonCuoiCuaSot",
      "TKNgungHoatDong",
      "TKNgungHoatDongKhongNhapHoan",
      "DcMaThungDaTonTaiVuiLongDeRieng",
      "DcMaThungKhongThuocKho",
      "DcMaThungKhongTonTai",
      "DcNhamSanPham",
      "DcSanPhamDaQuet",
      "DcThuaHangRoi",
      "DcThungDaQuet",
    ],
  }),
  created() {
    const items = [
      {
        title: this.$t("menus.notification"),
        img: require("@/assets/sidebars/push_notification.png"),
        route: "Notification",
        active: false,
        permissions: [],
      },
      {
        title: this.$t("menus.timekeeping"),
        img: require("@/assets/sidebars/timetable.png"),
        route: "Timekeeping",
        active: false,
        permissions: [],
        mobileCheck: true,
      },
      {
        title: this.$t("menus.overtime_timekeeping"),
        img: require("@/assets/sidebars/overtime.png"),
        route: "OvertimeTimekeeping",
        active: false,
        permissions: [],
        mobileCheck: true,
      },
      {
        title: this.$t("menus.goods_receipt_waiting_s"),
        img: require("@/assets/sidebars/nhapkho.png"),
        active: false,
        permissions: [
          "goods_receipt_waiting",
          "goods_receipt_fast",
          "goods_receipt_has_stamp",
        ],
        children: [
          {
            title: this.$t("labels.normal_receipt"),
            route: "GoodsReceiptWaiting",
            appendImg: require("@/assets/sidebars/receipt_normal.png"),
            permissions: ["goods_receipt_waiting"],
          },
          {
            title: this.$t("labels.fast_receipt"),
            route: "GoodsReceiptFast",
            appendImg: require("@/assets/sidebars/receipt_fast.png"),
            permissions: ["goods_receipt_fast"],
          },
          {
            title: this.$t("labels.has_stamp_receipt"),
            route: "GoodsReceiptHasStamp",
            appendImg: require("@/assets/sidebars/receipt_uid.png"),
            permissions: ["goods_receipt_has_stamp"],
          },
          {
            title: this.$t("menus.receipt_wrong_return"),
            route: "GoodsReceiptWrongReturn",
            appendImg: require("@/assets/sidebars/goods_return.png"),
            permissions: ["goods_receipt_wrong_return"],
          },
          {
            title: this.$t("labels.receipt_one"),
            route: "GoodsReceiptOne",
            appendImg: require("@/assets/sidebars/receipt_fast.png"),
            permissions: ["goods_receipt_one"],
          },
        ],
      },
      {
        title: this.$t("menus.goods_receipt_stowing_s"),
        img: require("@/assets/sidebars/lenke.png"),
        route: "GoodsReceiptStowing",
        active: false,
        permissions: ["goods_receipt_stowing"],
      },
      {
        title: this.$t("menus.goods_issue_pickup"),
        img: require("@/assets/sidebars/pickup.png"),
        route: "GoodsIssuePickup",
        active: false,
        permissions: ["goods_issue_pickup"],
      },
      {
        title: this.$t("menus.goods_issue_packing"),
        img: require("@/assets/sidebars/donggoi.png"),
        route: "GoodsIssuePacking",
        active: false,
        permissions: ["goods_issue_packing"],
      },
      {
        title: this.$t("menus.goods_issue_handover"),
        img: require("@/assets/sidebars/bangiaovanchuyen.png"),
        route: "GoodsIssueHandover",
        active: false,
        permissions: ["goods_issue_handover"],
      },
      {
        title: this.$t("menus.goods_return"),
        img: require("@/assets/sidebars/goods_return.png"),
        active: false,
        permissions: [
          "goods_return_receive_manager",
          "goods_return_import_manager",
          "goods_return_stowing_manager",
        ],
        children: [
          {
            title: this.$t("menus.goods_return_receive"),
            route: "GoodsReturnReceive",
            appendImg: require("@/assets/sidebars/nhanhoan.png"),
            permissions: ["goods_return_receive_manager"],
          },
          {
            title: this.$t("menus.goods_return_import"),
            route: "GoodsReturnImport",
            appendImg: require("@/assets/sidebars/nhaphoan.png"),
            permissions: ["goods_return_import_manager"],
          },
          {
            title: this.$t("menus.goods_return_stowing"),
            route: "GoodsReturnStowing",
            appendImg: require("@/assets/sidebars/lenke.png"),
            permissions: ["goods_return_stowing_manager"],
          },
        ],
      },
      {
        title: this.$t("menus.location"),
        img: require("@/assets/sidebars/goods_location_history.png"),
        active: false,
        permissions: [
          "location_change_manager",
          "location_set_manager",
          "location_roll_manager",
          "goods_location_history_manager",
          "location_warehouse",
        ],
        children: [
          {
            title: this.$t("menus.location_set_s"),
            route: "LocationSet",
            appendImg: require("@/assets/sidebars/goods_location_history.png"),
            permissions: ["location_set_manager"],
          },
          {
            title: this.$t("menus.location_change_s"),
            route: "LocationChange",
            appendImg: require("@/assets/sidebars/location_change.png"),
            permissions: ["location_change_manager"],
          },
          {
            title: this.$t("menus.location_roll_s"),
            route: "LocationRoll",
            appendImg: require("@/assets/sidebars/loaction_recall.png"),
            permissions: ["location_roll_manager"],
          },
          {
            title: this.$t("menus.goods_location_history_s"),
            route: "GoodsLocationHistory",
            appendImg: require("@/assets/sidebars/history_location.png"),
            permissions: ["goods_location_history_manager"],
          },
          {
            title: this.$t("menus.location_warehouse"),
            route: "WarehouseLocation",
            appendImg: require("@/assets/sidebars/locations_history.png"),
            permissions: ["location_warehouse"],
          },
        ],
      },
      {
        title: this.$t("menus.qc"),
        img: require("@/assets/sidebars/qc.png"),
        active: false,
        permissions: [
          "goods_uid_failed",
          "goods_qc",
          "goods_prepair",
          "goods_export_defective",
        ],
        children: [
          {
            title: this.$t("labels.goods_uid_failed"),
            appendImg: require("@/assets/sidebars/qc_list.png"),
            route: "GoodsUidFailed",
            permissions: ["goods_uid_failed"],
          },
          {
            title: this.$t("labels.qc_1"),
            appendImg: require("@/assets/sidebars/qc.png"),
            route: "GoodsQC",
            permissions: ["goods_qc"],
          },
          {
            title: this.$t("labels.qc_2"),
            appendImg: require("@/assets/sidebars/chuan_bi.png"),
            route: "GoodsPrepair",
            permissions: ["goods_prepair"],
          },
          {
            title: this.$t("labels.goods_error_export"),
            appendImg: require("@/assets/sidebars/xuathangloi.png"),
            route: "GoodsExportDefective",
            permissions: ["goods_export_defective"],
          },
        ],
      },
      {
        title: this.$t("labels.mgmt"),
        img: require("@/assets/sidebars/mgmt.png"),
        active: false,
        permissions: [
          "goods_receipt_manager",
          "goods_receipt_record",
          "goods_issue_pickup_history",
          "goods_issue_manager",
          "goods_return_manager",
          "goods_uid_manager",
          "goods_report_manager",
          "packaging_manager",
          "employee_manager",
          "admin_day_off",
        ],
        children: [
          {
            title: this.$t("labels.goods_receipt_manager_s"),
            appendImg: require("@/assets/sidebars/quanlynhapkho.png"),
            route: "GoodsReceipt",
            active: false,
            permissions: ["goods_receipt_manager"],
          },
          {
            title: this.$t("labels.goods_receipt_record_s"),
            appendImg: require("@/assets/sidebars/bienban.png"),
            route: "GoodsReceiptRecord",
            active: false,
            permissions: ["goods_receipt_record"],
          },
          {
            title: this.$t("labels.goods_issue_pickup_history"),
            appendImg: require("@/assets/sidebars/pickup.png"),
            route: "GoodsIssuePickupHistory",
            active: false,
            permissions: ["goods_issue_pickup_history"],
          },
          {
            title: this.$t("labels.goods_issue_manager"),
            appendImg: require("@/assets/sidebars/quanlyxuatkho.png"),
            route: "GoodsIssue",
            active: false,
            permissions: ["goods_issue_manager"],
          },
          {
            title: this.$t("labels.goods_return_manager"),
            appendImg: require("@/assets/sidebars/quanlyhoan.png"),
            route: "GoodsReturn",
            active: false,
            permissions: ["goods_return_manager"],
          },
          {
            title: this.$t("labels.goods_uid_manager"),
            appendImg: require("@/assets/sidebars/uid.png"),
            route: "GoodsUid",
            active: false,
            permissions: ["goods_uid_manager"],
          },
          {
            title: this.$t("labels.goods_manager_s"),
            appendImg: require("@/assets/sidebars/report.png"),
            route: "GoodsList",
            active: false,
            permissions: ["goods_report_manager"],
          },
          {
            title: this.$t("labels.packaging_s"),
            appendImg: require("@/assets/sidebars/supplies.png"),
            route: "Packaging",
            active: false,
            permissions: ["packaging_manager"],
          },
          {
            title: this.$t("labels.employee_manager_s"),
            appendImg: require("@/assets/sidebars/hr.png"),
            route: "Employee",
            active: false,
            permissions: ["employee_manager"],
          },
          {
            title: this.$t("labels.day_off"),
            appendImg: require("@/assets/sidebars/day_off.png"),
            route: "DayOff",
            active: false,
            permissions: ["admin_day_off"],
          },
        ],
      },
      {
        title: this.$t("labels.tools"),
        img: require("@/assets/sidebars/settings.png"),
        active: false,
        permissions: [
          "download_goods_stamp",
          "inventory",
          "goods_generate_stamp_again",
          "check_basket",
          "find_pickup_basket",
          "warehouse_dashboard_master",
          "employee_export_order",
        ],
        children: [
          {
            title: this.$t("menus.download_goods_stamp"),
            appendImg: require("@/assets/sidebars/generate_pdf.png"),
            route: "GoodsPrintStamp",
            active: false,
            permissions: ["download_goods_stamp"],
            desktopCheck: true,
          },
          {
            title: this.$t("menus.inventory"),
            appendImg: require("@/assets/sidebars/nhapkho.png"),
            route: "Inventory",
            active: false,
            permissions: ["inventory"],
          },
          {
            title: this.$t("menus.re_print_stamp"),
            route: "GoodsRePrintStamp",
            appendImg: require("@/assets/sidebars/generate_pdf.png"),
            permissions: ["goods_generate_stamp_again"],
            active: false,
          },
          {
            title: this.$t("menus.check_basket"),
            appendImg: require("@/assets/sidebars/supplies.png"),
            route: "CheckBasket",
            active: false,
            permissions: ["check_basket"],
          },
          {
            title: this.$t("menus.find_pickup_basket"),
            appendImg: require("@/assets/sidebars/supplies.png"),
            route: "FindPickupBasket",
            active: false,
            permissions: ["find_pickup_basket"],
          },
          {
            title: this.$t("menus.dashboard_master"),
            appendImg: require("@/assets/sidebars/thongkenhanh.png"),
            route: "DashboardMaster",
            active: false,
            permissions: ["warehouse_dashboard_master"],
          },
          {
            title: this.$t("labels.employee_export_order"),
            appendImg: require("@/assets/sidebars/quanlyxuatkho.png"),
            route: "GoodsIssueEmployeeExport",
            active: false,
            permissions: ["employee_export_order"],
          },
        ],
      },
      {
        title: this.$t("menus.dc"),
        img: require("@/assets/sidebars/dc.png"),
        active: false,
        permissions: [
          "dc_warehouse_order_pickup",
          "dc_warehouse_order_handover",
          "dc_warehouse_order_list",
          "dc_warehouse_receipt",
          "dc_warehouse_check_box",
          "dc_warehouse_goods",
          "dc_warehouse_receipt_history",
        ],
        children: [
          {
            title: this.$t("menus.dc_pickup"),
            appendImg: require("@/assets/sidebars/pickup.png"),
            route: "DcPickup",
            active: false,
            permissions: ["dc_warehouse_order_pickup"],
          },
          {
            title: this.$t("menus.dc_handover"),
            appendImg: require("@/assets/sidebars/bangiaovanchuyen.png"),
            route: "DcHandover",
            active: false,
            permissions: ["dc_warehouse_order_handover"],
          },
          {
            title: this.$t("menus.dc_receipt"),
            appendImg: require("@/assets/sidebars/nhapkho.png"),
            route: "DcReceipt",
            active: false,
            permissions: ["dc_warehouse_receipt"],
          },
          {
            title: this.$t("menus.dc_receipt_present"),
            appendImg: require("@/assets/sidebars/nhapkho.png"),
            route: "DcReceiptPresent",
            active: false,
            permissions: ["dc_warehouse_receipt_present"],
          },
          {
            title: this.$t("menus.dc_checkbox"),
            appendImg: require("@/assets/sidebars/nhapkho.png"),
            route: "DcCheckBox",
            active: false,
            permissions: ["dc_warehouse_check_box"],
          },
          {
            title: this.$t("menus.dc_goods"),
            appendImg: require("@/assets/sidebars/report.png"),
            route: "DcGoods",
            active: false,
            permissions: ["dc_warehouse_goods"],
          },
          {
            title: this.$t("menus.dc_order"),
            appendImg: require("@/assets/sidebars/quanlyxuatkho.png"),
            route: "DcOrder",
            active: false,
            permissions: ["dc_warehouse_order"],
          },
          {
            title: this.$t("menus.dc_receipt_history"),
            appendImg: require("@/assets/sidebars/quanlynhapkho.png"),
            route: "DcReceiptHistory",
            active: false,
            permissions: ["dc_warehouse_receipt_history"],
          },
        ],
      },
      {
        title: this.$t("menus.order_request"),
        img: require("@/assets/sidebars/order_request.png"),
        route: "InternalRequest",
        active: false,
        permissions: ["internal_request_manager"],
      },
      {
        title: this.$t("menus.goods_uid_find"),
        img: require("@/assets/sidebars/timhang.png"),
        route: "GoodsFind",
        active: false,
        permissions: [],
        mobileCheck: true,
        elCountName: "count_find_goods",
      },
      {
        title: this.$t("menus.goods_uid_find_manager"),
        img: require("@/assets/sidebars/yeucautimhang.png"),
        route: "GoodsFindManager",
        active: false,
        permissions: ["find_goods_manager"],
      },
      {
        title: this.$t("menus.dashboard"),
        img: require("@/assets/sidebars/thongkenhanh.png"),
        route: "Dashboard",
        active: false,
        permissions: ["dashboard_manager"],
      },
      {
        title: this.$t("menus.goods_issue_detail"),
        img: require("@/assets/sidebars/quanlyxuatkho.png"),
        route: "GoodsIssueDetail",
        active: false,
        permissions: ["order_detail_manager"],
      },
      {
        title: this.$t("menus.administration"),
        img: require("@/assets/sidebars/quantri.png"),
        route: "Administration",
        active: false,
        permissions: [
          "warehouse_admin_timekeeping",
          "warehouse_admin_payroll",
          "warehouse_admin_bill",
          "warehouse_admin_package",
        ],
      },
    ];
    this.items = [...items];

    const lang = localStorage.getItem("_lang") || "vi";
    if (lang == "en") {
      this.audioPrefix = "en_";
    }
  },
  methods: {
    isMobile,
    playErrorAudio() {
      document.getElementById("error_sound_player").play();
    },
    playSuccessAudio() {
      document.getElementById("success_sound_player").play();
    },
    playAudioByName(name) {
      document.getElementById(`${name}_player`).play();
    },
    async backToAdmin() {
      await httpClient.post("/me/v1/logout");
      localStorage.setItem("_st", this.sto);
      localStorage.removeItem("_sto");
      window.location.reload();
    },
    async logout() {
      await httpClient.post("/common/v1/leave-table");
      await httpClient.post("/me/v1/logout");
      localStorage.removeItem("_st");
      localStorage.removeItem("_sto");
      localStorage.removeItem("_pktb");
      localStorage.removeItem("_pbct1");
      localStorage.removeItem("_wh");
      // window.location.reload();
      window.location = process.env.VUE_APP_V2_HOME_PATH;
    },
    checkRoute(routeName) {
      if (this.$route.name === routeName) {
        window.location.reload();
      }
    },
    onNotifyChange(count) {
      this.notifyCount = count;
    },

    listenSocket() {
      window.socket.on("notification_push", (data) => {
        this.notifyCount += 1;
        const title = data.title;
        if (title.includes("[Hỏa tốc]")) {
          document.getElementById("DonHoaToc_player").play();
        } else {
          this.$vToastify.warning(
            this.$t("messages.new_noti", { title: data.title })
          );
        }
      });
      if (this.checkPermission(["super_admin"])) {
        window.socket.on("order_delivery_or_stamp_error", (data) => {
          this.$vToastify.error(
            this.$t("messages.error_order_label", {
              tracking_id: data.tracking_id,
              content: data.message,
            })
          );
        });
      }
    },

    changeLanguage(lang) {
      localStorage.setItem("_lang", lang);
      window.location.reload();
    },

    changeWarehouse(wh) {
      localStorage.setItem("_wh", wh);
      window.location.reload();
    },

    showDialogChangePassword() {
      this.dialogChangePassword = true;
    },

    hideDialogChangePassword() {
      this.dialogChangePassword = false;
      this.oldPassword = null;
      this.newPassword = null;
      this.passwordShow = false;
    },
    async changePassword() {
      if (!this.oldPassword || !this.newPassword) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/me/v1/update-password", {
          old_password: this.oldPassword,
          new_password: this.newPassword,
        });
        this.hideDialogChangePassword();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
  mounted() {
    window.socket.on("auth", (data) => {
      console.log(data);
    });
    window.socket.emit("auth", window.me.auth_key, "employee");

    const wh = localStorage.getItem("_wh") || 0;

    this.$root.$on("playErrorAudio", this.playErrorAudio);
    this.$root.$on("playSuccessAudio", this.playSuccessAudio);
    this.$root.$on("playAudioByName", this.playAudioByName);
    this.sto = localStorage.getItem("_sto");
    const { employee, notify, count_find_goods, warehouses } = window.me;
    this.employee = { ...employee };
    this.warehouses = [...warehouses];
    const warehouse = [...warehouses].find((warehouse) => warehouse.id == wh);
    if (warehouse) {
      this.warehouse = { ...warehouse };
    } else {
      this.warehouse = { ...warehouses[0] };
    }
    this.notifyCount = notify;
    this.menuCount = { count_find_goods };
    this.listenSocket();
  },
  beforeDestroy() {
    this.$root.$off("playErrorAudio", this.playErrorAudio);
    this.$root.$off("playSuccessAudio", this.playSuccessAudio);
    this.$root.$off("playAudioByName", this.playAudioByName);
  },
};
</script>
